export const generateRandomString = () => {
  let text: string = "";
  const possible: string =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const ltrim = (str: string, characters: string = "s") => {
  return str.replace(new RegExp("^[" + characters + "]+"), "");
};

export const rtrim = (str: string, characters: string = "s") => {
  return str.replace(new RegExp("[" + characters + "]+$"), "");
};

export const trimLeftSlashes = (str: string) => {
  return ltrim(str, "/");
};
