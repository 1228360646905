import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.page.html",
  styleUrls: ["./orders.page.scss", "../store-account-page.scss"]
})
export class OrdersPage implements OnInit {
  public orders = [];

  constructor() {}

  ngOnInit() {
    // TODO: getCustomerOrders()
  }
}
